import JLINCAPI from 'lib/JLINCAPI'

class AliceAPI extends JLINCAPI {

  sendHelpRequest(helpRequest){
    return this.postJSON('/help_request', helpRequest)
  }

  verifyExternalIdentity({ verificationCode }){
    return this.postJSON('/external_identities/verify', { verificationCode })
  }

  getPublicOrganizations(){
    return this.getJSON('/public_organizations')
  }

  getOrganizationApikeyAvailability(organizationApikey){
    return this.getJSON(`/organizations/${organizationApikey}/availability`)
  }

  createOrganization({ organization, organizationMembershipInviteToken }){
    return this.postJSON(`/organizations`, { organization, organizationMembershipInviteToken })
  }

  updateOrganization({ organizationApikey, changes }){
    return this.postJSON(`/organizations/${organizationApikey}`, changes)
  }

  getOrganization({ organizationApikey }){
    return this.getJSON(`/organizations/${organizationApikey}`)
  }

  // we have to batch these requests incase we go over the 2083 url character limit
  getOrganizations({ organizationApikeys }){
    const apikeys = Array.from(new Set(organizationApikeys))
    const batches = ['']
    apikeys.forEach(apikey => {
      if (batches[0] === '') {
        batches[0] = apikey
        return
      }
      const addition = batches[0] + '~' + apikey
      if (addition.length <= 2000) batches[0] = addition
      else batches.unshift(apikey)
    })
    return Promise.all(batches.map(apikeys =>
      this.getJSON(`/organizations`, { apikeys })
    )).then(responses => {
      return responses.reduce((a, b) => {
        const merged = {}
        'organizations memberships publicProfiles'.split(' ').forEach(key => {
          merged[key] = [...a[key], ...b[key]]
        })
        return merged
      })
    })
  }

  getOrganizationAccountdata({ organizationApikey }){
    return this.getJSON(`/organizations/accountData/${organizationApikey}`)
      .then(({ organizationAccountData }) => organizationAccountData)
  }

  updateOrganizationsAccountData({ organizationApikeys, changes }){
    return this.postJSON(`/organizations/accountData`, { organizationApikeys, changes })
  }

  getDefaultAccountdata(){
    return this.getJSON(`/default-account-data`)
      .then(({ defaultAccountData }) => defaultAccountData)
  }

  updateDefaultAccountData({ defaultAccountData }){
    return this.postJSON(`/default-account-data`, { defaultAccountData })
      .then(({ defaultAccountData }) => defaultAccountData)
  }

  updatePreferences({ preferences }){
    return this.postJSON(`/preferences`, { preferences })
  }

  getExternalIdentities(){
    return this.getJSON(`/external_identities`)
  }

  addExternalIdentity({ type, value }){
    return this.postJSON(`/external_identities`, { type, value })
  }

  addAuthenticatorApp(){
    return this.postJSON(`/authenticator_app/add`)
  }

  getAuthenticatorApp(){
    return this.postJSON(`/authenticator_app/get`)
  }

  verifyAuthenticatorApp({code}){
    return this.postJSON(`/authenticator_app/verify`, {code})
  }

  deleteAuthenticatorApp(){
    return this.postJSON(`/authenticator_app/delete`)
  }

  webauthRegistration(){
    return this.postJSON('/webauthn/register_start')
  }

  addPasskey({authRes}){
    return this.postJSON(`/webauthn/register_finish`, {authRes})
  }

  generateAuthenticationOptions(){
    return this.postJSON('/webauthn/login_start')
  }

  getPasskeys(){
    return this.postJSON(`/webauthn/list`)
  }

  deletePasskey({credentialID}){
    return this.postJSON(`/webauthn/delete`, {credentialID})
  }

  getRequire2FA(){
    return this.postJSON(`/require_2fa/`, {})
  }

  updateRequire2FA(value){
    return this.postJSON(`/require_2fa/update`, { value })
  }

  getRecoveryCodes(){
    return this.postJSON(`/recovery_codes/get`, {})
  }

  generateRecoveryCodes(){
    return this.postJSON(`/recovery_codes/generate`, {})
  }

  deleteRecoveryCodes(){
    return this.postJSON(`/recovery_codes/delete`, {})
  }

  resendExternalIdentityVerificationCode({ value }){
    return this.postJSON(`/external_identities/resend_verification_code`, { value })
  }

  deleteExternalIdentity({ value }){
    return this.postJSON(`/external_identities/delete`, { value })
  }

  createBuyingInterest({ buyingInterest }){
    return this.postJSON(`/buying_interest/create`, { buyingInterest })
  }

  getBuyingInterests(){
    return this.getJSON(`/buying_interest`)
  }

  deleteBuyingInterest({ buyingInterestUid }){
    return this.postJSON(`/buying_interest/delete`, { buyingInterestUid })
  }

  getSisaEventsForOrganization({ organizationApikey }){
    return this.getJSON(`/organizations/sisa_events/${organizationApikey}`)
      .then(({ organizationSisaEvents }) => organizationSisaEvents)
  }

  getOrganizationByApiKey({ organizationApikey }){
    return this.getJSON(`/organization/${organizationApikey}`)
      .then(({ organization }) => organization)
  }

  requestOrganizationDelete(organizationApikey){
    return this.postJSON(`/request_organization_delete/${organizationApikey}`)
  }

  getFeedPosts({ username, organizationApikey, published, order, before, pageSize }){
    published = published ? '1' : undefined
    if (before instanceof Date) before = before.toISOString()
    return this.getJSON(`/feed/posts`, { username, organizationApikey, published, order, before, pageSize })
  }

  getFeedPost({ feedPostUid }){
    return this.getJSON(`/feed/posts/${feedPostUid}`)
  }

  getFeedPostProvenance({ feedPostUid }){
    return this.getJSON(`/feed/posts/${feedPostUid}/provenance`)
  }

  createFeedPost({ post }){
    return this.postJSON(`/feed/posts`, { post })
  }

  updateFeedPost({ feedPostUid, changes }){
    return this.postJSON(`/feed/posts/${feedPostUid}`, { changes })
  }

  repostFeedPost({
    feedPostUid,
    posterOrganizationApikey,
    feedOrganizationApikey,
    visibleTo,
    maxVisibleTo,
  }){
    return this.postJSON(`/feed/posts/${feedPostUid}/repost`, {
      posterOrganizationApikey,
      feedOrganizationApikey,
      visibleTo,
      maxVisibleTo,
    })
  }

  publishFeedPost({ feedPostUid, visibleTo, maxVisibleTo }){
    return this.postJSON(`/feed/posts/${feedPostUid}/publish`, {visibleTo, maxVisibleTo})
  }

  getFeedPostsDeletingReasons(){
    return this.getJSON(`/deleting-reasons`)
  }

  deleteFeedPost({ feedPostUid, comment, reason, sendNotification }){
    return this.postJSON(`/feed/posts/${feedPostUid}/delete`, { feedPostUid, comment, reason, sendNotification })
  }

  hideFeedPostFromMe({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/hide-from-me`)
  }

  hideFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/hide`, { feedPostUid })
  }

  voteOnFeedPost({ feedPostUid, vote }){
    return this.postJSON(`/feed/posts/${feedPostUid}/vote`, { vote })
  }

  pinFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/pin`)
  }

  unpinFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/unpin`)
  }

  getPinnedPostsForOrganization({ organizationApikey }) {
    return this.getJSON(`/organizations/${organizationApikey}/feed/posts/pinned`)
  }

  createFeedPostComment({ feedPostUid, comment }){
    return this.postJSON(`/feed/posts/${feedPostUid}/comments`, { comment })
  }

  deleteFeedPostComment({ commentUid }){
    return this.postJSON(`/feed/comments/${commentUid}/delete`)
  }

  getFeedPostComments({ feedPostUid, before }){
    return this.getJSON(`/feed/posts/${feedPostUid}/comments`, { before })
  }

  getAssetUploadToken(){
    return this.postJSON(`/create_asset_upload_token`)
      .then(({ assetUploadToken }) => assetUploadToken)
  }

  getProductsAndServices(){
    return this.getJSON(`/products_and_services`)
  }

  createProductOrService({ productOrService }){
    return this.postJSON(`/products_and_services`, { productOrService })
  }

  getProductOrService({ uid }){
    return this.getJSON(`/products_and_services/${uid}`)
  }

  deleteProductOrService({ uid }){
    return this.postJSON(`/products_and_services/${uid}/delete`)
  }

  getPublicProfile(){
    return this.getJSON('/public_profile')
  }

  updatePublicProfile({ publicProfile }){
    return this.postJSON(`/public_profile`, { publicProfile })
  }

  getPublicProfiles({ dids, usernames }){
    return this.getJSON('/public_profiles', { 'dids[]': dids, 'usernames[]': usernames })
  }

  async getOrganizationMemberships(){
    return (await this.getJSON('/organizations/memberships')).organizationMemberships
  }

  createOrganizationMembership({ organizationApikey, inviteToken, memberUserDid }){
    return this.postJSON(
      `/organizations/${organizationApikey}/memberships`,
      { inviteToken, memberUserDid },
    )
  }

  updateOrganizationMembership({ organizationApikey, organiztionMembershipUid, changes }){
    return this.postJSON(`/organizations/${organizationApikey}/memberships/${organiztionMembershipUid}`, changes)
  }

  deleteOrganizationMembership({ organizationApikey, membershipUid }){
    return this.postJSON(`/organizations/${organizationApikey}/memberships/${membershipUid}/delete`)
  }

  resolveOrganizationMembershipRequest({organizationApikey, membershipRequestUid, accept}){
    return this.postJSON(
      `/organizations/${organizationApikey}/membership_requests/${membershipRequestUid}/resolve`,
      { accept },
    )
  }

  createOrganizationMembershipInvite({ organizationApikey }){
    return this.postJSON(`/organizations/${organizationApikey}/membership_invites`)
  }

  getOrganizationMembershipInvite({ token }){
    return this.getJSON(`/organizations/membership_invites/${token}`)
  }

  getOrganizationMembershipInvites({ organizationApikey }){
    return this.getJSON(`/organizations/${organizationApikey}/membership_invites`)
  }

  async getFormerMemberships(organizationApikey){
    return (await this.getJSON(`/organizations/${organizationApikey}/former_memberships`)).organizationFormerMemberships
  }

  getMembershipsForPublicProfile({ publicProfileDid }){
    return this.getJSON(`/public_profile/${publicProfileDid}/memberships`)
  }

  createCovidReport(covidReport) {
    return this.postJSON(`/covid_report`, { covidReport })
  }

  getCovidReports() {
    return this.getJSON(`/covid_reports`)
  }

  getNotifications({ before }) {
    return this.getJSON('/notifications', { before })
  }

  getNotificationSettings() {
    return this.getJSON('/notifications/settings')
  }

  updateNotificationSettings(notificationSettings) {
    return this.postJSON('/notifications/settings', { notificationSettings })
  }

  markNotificationsAsRead({ uids }){
    return this.postJSON(`/notifications/seen`, { uids })
  }

  subscribeToOrganizationFeed({ organizationApikey, subscriberOrganizationApikey }) {
    return this.postJSON(`/organizations/${organizationApikey}/feed/subscriptions`, { subscriberOrganizationApikey })
  }

  unsubscribeFromOrganizationFeed({ organizationFeedSubscriptionUid, subscriberOrganizationApikey }) {
    return this.postJSON(
      `/organizations/feed_subscriptions/${organizationFeedSubscriptionUid}/delete`,
      { subscriberOrganizationApikey },
    )
  }

  getMyOrganizationFeedSubscriptions() {
    return this.getJSON(`/organizations/feed_subscriptions`)
  }

  getOrganizationFeedSubscriptions(organizationApikey) {
    return this.getJSON(`/organizations/${organizationApikey}/feed/subscriptions`)
  }

  search(searchQuery, page){
    return this.getJSON('/search', { q: searchQuery, page })
  }

  searchForOrganizations(searchQuery, page){
    return this.getJSON('/organizations/search', { q: searchQuery, page })
  }

  inviteUserToOrganization({ organizationApikey, publicProfileDid }){
    return this.postJSON(`/organizations/${organizationApikey}/invite/${publicProfileDid}`, {})
  }

  getUrlPreview(url){
    return this.getJSON(`/url-preview`, { url })
  }
  getSuggestedHubs(profileDids){
    return this.postJSON(`/suggestions`, { profileDids })
  }

  async getChatSynopsis(){
    return (await this.getJSON('/chat')).chatSynopsis
  }

  async createChatChannel(chatChannel){
    return (await this.postJSON(`/chat`, chatChannel)).chatChannel
  }

  leaveChatChannel(chatChannelUid) {
    return this.postJSON(`/chat/${chatChannelUid}/leave`)
  }

  async deleteChatChannel(chatChannelUid){
    return await this.postJSON(`/chat/${chatChannelUid}/delete`)
  }

  async addMembersToChatChannel(chatChannelUid, memberUserDids){
    return await this.postJSON(`/chat/${chatChannelUid}/add_members`, { memberUserDids })
  }

  async removeMembersFromChatChannel(channelUid, memberUserDids){
    return await this.postJSON(`/chat/${channelUid}/remove_members`, { memberUserDids })
  }

  async assignChatChannelAdminRole(chatChannelUid, adminUserDid){
    return await this.postJSON(`/chat/${chatChannelUid}/assign_admin`, { adminUserDid })
  }

  async removeChatChannelAdminRole(chatChannelUid, adminUserDid){
    return await this.postJSON(`/chat/${chatChannelUid}/remove_admin`, { adminUserDid })
  }

  async addChatChannelDetails(chatChannelUid, chatName, imageUrl, description) {
    return await this.postJSON(`/chat/${chatChannelUid}/channel_details`, {
      chatName,
      imageUrl,
      description
    })
  }

  async getChatChannelMembershipDetails(chatChannelUid) {
    return await this.getJSON(`/chat/${chatChannelUid}/memberships`)
  }

  async getChatMessages(chatChannelUid){
    return (await this.getJSON(`/chat/${chatChannelUid}`)).chatMessages
  }

  async createChatMessage(chatChannelUid, message, caption,  urlPreviewId){
    return (await this.postJSON(`/chat/${chatChannelUid}`, { message, urlPreviewId, caption })).chatMessage
  }

  async replyToChatMessage(chatChannelUid, messageUid, replyContent, urlPreviewId, sendToMainChat) {
    const response = await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/reply`,
      { replyContent, urlPreviewId, sendToMainChat })
    if (!response || !response.chatMessage) {
      console.error("Unexpected response structure from server:", response)
    }
    return response.chatMessage
  }
  async getMutedUsers({ organizationDid }) {
    try {
      const response = await this.getJSON(`/muted_users/${organizationDid}`)
      return response
    } catch (error) {
      console.error("Error in getMutedUsers method:", error)
      throw error
    }
  }


  async unMuteUser({ organizationDid, userDid, unMuteDetails }) {
    try {
      const response = await this.postJSON(`/unmute_user/${organizationDid}/${userDid}`, unMuteDetails)
      return response
    } catch (error) {
      console.error("Error in unmuteUser method:", error)
      throw error
    }
  }
  async muteUser({ organizationDid, userDid, muteDetails }) {
    try {
      const response = await this.postJSON(`/mute_user/${organizationDid}/${userDid}`, muteDetails)
      return response
    } catch (error) {
      console.error("Error in muteUser method:", error)
      throw error
    }
  }

  async forwardChatMessage({chatChannelUids, messageUid, message}) {

    const response = await this.postJSON(`/chat/${messageUid}/forward`, { channelUids:chatChannelUids, message })
    if (!response || !response.chatMessage) {
      console.error("Unexpected response structure from server:", response)
    }
    return response.chatMessage
  }

  async markChatMessagesAsRead(messageUid){
    return (await this.postJSON(`/chat/${messageUid}/read`)).chatMessages
  }

  async changeChatChannelAttributes(chatChannelUid, attribute){
    return await this.postJSON(`/channel_attributes`, { chatChannelUid, attribute })
  }

  async getChatChannelsAttributes(){
    const chatChannelsAttributes = await this.getJSON(`/channel_attributes`)
    return chatChannelsAttributes
  }

  async editChatMessage(chatChannelUid, messageUid, message, urlPreviewId){
    return (await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/edit`, { message, urlPreviewId })).chatMessage
  }

  async deleteChatMessage(
    chatChannelUid,
    messageUid,
    deletionReason,
    AdminComment
  ) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/delete`, {
        deletionReason,
        AdminComment,
      })
    ).chatMessage
  }


  async undoDeleteChatMessage(chatChannelUid, messageUid, action) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/undo-delete?action=${action}`)).chatMessage
  }


  async createChatMessageReaction(chatChannelUid, messageUid, reaction) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/reactions`, { reaction })
    ).reaction
  }

  async removeChatMessageReaction(chatChannelUid, messageUid, reaction) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/reactions/remove`, { reaction })
    ).reactionRemovalResult
  }


  async getOrganizationNetworkMemberships(organizationApikey){
    const { organizationNetworkMemberships } =
      await this.getJSON(`/organizations/${organizationApikey}/network/memberships`)
    return organizationNetworkMemberships
  }

  async createOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey){
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships`,
      { memberOrganizationApikey },
    )
    return organizationNetworkMembership
  }

  async updateOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey, changes){
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/${memberOrganizationApikey}`,
      changes,
    )
    return organizationNetworkMembership
  }

  async removeOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey){
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/${memberOrganizationApikey}/remove`,
    )
    return organizationNetworkMembership
  }

  async sortOrganizationNetworkMemberships(organizationApikey, sortOrder){
    const { organizationNetworkMemberships } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/sort`,
      { sortOrder },
    )
    return organizationNetworkMemberships
  }

  async getChatChannelsForUser(){
    const chat_channels = await this.getJSON(`/personal-chats`)
    return chat_channels
  }

  async starChatMessage(chatChannelUid, messageUid){
    return (await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/star`, { chatChannelUid, messageUid }))
  }

  async unstarChatMessage(chatChannelUid, messageUid){
    return (await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/unstar`, { chatChannelUid, messageUid }))
  }

  async getStarredChatMessages(chatChannelUid){
    const result = await this.getJSON(`/chat/${chatChannelUid}/starred`)
    return result?.starredChatMessages
  }

  async searchInChat(chatChannelUid, keyword){
    const { result } = await this.getJSON(`/chat/${chatChannelUid}/chat_searching`, { keyword })
    return result
  }

  async hideOrganizationMembership({ organizationApikey, memberUserDid }){
    return await this.postJSON(`/organizations/${organizationApikey}/memberships/${memberUserDid}/hide`)
  }

  async unhideOrganizationMembership({ organizationApikey, memberUserDid }){
    return await this.postJSON(`/organizations/${organizationApikey}/memberships/${memberUserDid}/unhide`)
  }

  async updateMembershipDisplayOrder({ organizationApikey, displayOrder, memberUserDid}) {
    return await this.postJSON(`/organizations/${organizationApikey}/memberships/display_order/update`,
      { displayOrder, memberUserDid })
  }

  async getChatChannelsForPublicProfile(organizationApikey, publicProfileDid) {
    return (await this.getJSON(`/chat_channels/${organizationApikey}/${publicProfileDid}`)).chatChannels
  }
}

export default new AliceAPI({ urlPrefix: JLINC_A_API })
